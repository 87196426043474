// src/Header.js
import React, {useEffect} from 'react';
import { useAuth } from './AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";
import 'bootstrap-icons/font/bootstrap-icons.css';

function Header() {

    const { isAuthenticated, logout, login } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        try {
            const response = await axios.get('/api/me');
            if (response.data.message !== 'OK') {
                logout()
                navigate('/login');
            } else {
                login()
            }
        } catch (error) {
            // console.log('OOOPS', error)
            logout()
            navigate('/login');
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                {isAuthenticated && (
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>)
                }
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        {isAuthenticated ? (
                            <>
                                <button className="btn btn-primary" onClick={logout}><i
                                    className="bi bi-box-arrow-left"></i> Logout</button>
                            </>
                        ) : <></>}
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
