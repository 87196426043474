// src/Cards.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Cards() {
    const [cards, setCards] = useState([]);
    const [trx, setTrx] = useState(null);
    const [active, setActive] = useState(null);
    useEffect(() => {
        const fetchCards = async () => {
            try {
                const response = await axios.get('/api/cards-all');
                setCards(response.data.sort(compareDates));
            } catch (error) {
                console.error("Failed to fetch cards", error);
            }
        };

        fetchCards();
    }, []);

    const fetchCard = async (id) => {
        try {
            setActive(id)
            const response = await axios.get(`/api/card/${id}`);
            setTrx(response.data.sort(compareDates));
        } catch (error) {
            console.error("Failed to fetch card", error);
        }
    };

    const compareDates = (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
    }

    return cards ? (
        <div className="container mt-3">
            <h2>Card List</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>Bin</th>
                    <th>Card #</th>
                    <th>Balance</th>
                    <th>Spent</th>
                    <th>Fees</th>
                    <th>Top Up</th>
                    <th>Withdrawn</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {cards.map(card => (
                    <tr className={active === card.id ? 'activeCell': 'row-cell'} key={card.id} onClick={()=>fetchCard(card.id)}>
                        <td><img height="30" src={`${process.env.PUBLIC_URL}/${card.bin.scheme}.png`} alt="Logo" /> {card.bin.code}</td>
                        <td>{(card.mask).substring(8, 16)}</td>
                        <td>{card.balance.available.toFixed(2)} <p className="currency"> {`${((card?.balance?.available * card?.balance?.rate) || 0).toFixed(2)} €`}</p></td>
                        <td>{card.balance.used.toFixed(2)} <p className="currency"> {`${((card?.balance?.used * card?.balance?.rate) || 0).toFixed(2)} €`}</p></td>
                        <td>{card.balance.fees.toFixed(2)} <p className="currency"> {`${((card?.balance?.fees * card?.balance?.rate) || 0).toFixed(2)} €`}</p></td>
                        <td>{(card?.balance?.topUp + card?.balance?.opening).toFixed(2)} <p className="currency"> {`${((card?.balance?.topUp * card?.balance?.rate + card?.balance?.opening * card?.balance?.rate) || 0).toFixed(2)} €`}</p></td>
                        <td>{card.balance.withdrawal.toFixed(2)} <p className="currency"> {`${((card?.balance?.withdrawal * card?.balance?.rate) || 0).toFixed(2)} €`}</p></td>
                        <td>{card.status}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <br/>
            {trx && (<>
                <h2>Transactions List</h2>
                <table className="table table2" width="100%">
                    <thead>
                    <tr>
                        <th>Date:time</th>
                        <th width="30%">Sum</th>
                        <th>Status</th>
                        <th>Merchant</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trx.map((trx, i) => (
                        <tr className={trx.status === 'FAILED' ? 'grayCell':''} key={i}>
                            <td>{new Date(trx.date).toLocaleString()}</td>
                            <td className={trx.billing_amount < 0 ? 'redC': 'greenC'}>{`${trx.billing_amount} ${trx.billing_currency}`} <p className="currency"> {`${trx.amount} ${trx.currency}`}</p></td>
                            <td>{trx.status}</td>
                            <td>{trx.merchant_name}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>)}
        </div>
    ) : null;
}
export default Cards;
